// Libraries
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
// Utils
import {
  indexTransformer,
  notificationsTransformer,
  noOpTransformer,
  showTransformer,
} from './transformers';
import { useSnackbar } from 'hooks';
// Constants
import { ROUTES } from 'features/navigation';
import * as Constants from './api.constants';
import {
  API_VERSION,
  IAPI,
  PostPatchRequestOptions,
  RequestOptions,
  ResourceType,
  SearchRequestOptions,
} from './api.types';
const { RESOURCE_TYPES } = Constants;
const { FORBIDDEN_403, NOT_FOUND_404 } = ROUTES;

const isDevelopment = process.env.REACT_APP_ENV === 'development';

axios.defaults.baseURL = Constants.API_BASE_URL;

export const useApi = (): IAPI => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  /*========== HELPERS ==========*/
  const getToken = useCallback(
    function () {
      return getAccessTokenSilently({
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
      });
    },
    [getAccessTokenSilently]
  );

  const handleCustomAndDefaultError = useCallback(
    (error: unknown, handleError?: (error: unknown) => void) => {
      const axiosError = (error as unknown) as AxiosError<{
        errors: string[];
        errorMessage?: string;
      }>;

      // We intercept expected status codes and handle them appropriately,
      // unless a specific errorHandler was provided to useApi
      if (
        axiosError.response?.status === Constants.FORBIDDEN_STATUS &&
        !handleError
      ) {
        navigate(FORBIDDEN_403.route);
        Rollbar.info(
          `${axiosError.message} at ${axiosError.request.responseURL}`
        );
        return;
      }
      if (
        axiosError.response?.status === Constants.NOT_FOUND_STATUS &&
        !handleError
      ) {
        navigate(NOT_FOUND_404.route);
        Rollbar.info(
          `${axiosError.message} at ${axiosError.request.responseURL}`
        );
        return;
      }

      // The server can respond with standard error messages.
      // These are expected, such as validation errors.
      // {errors: ["An array of errors"], errorMessage: "A display string for the error"}
      // We don't need to send these to Rollbar as actual errors.

      if (axiosError.response?.data?.errors) {
        console.error(axiosError);
        Rollbar.warn(
          axiosError.response?.data.errorMessage || axiosError.message
        );
        handleError
          ? handleError(error as Error)
          : showSnackbar(
              axiosError.response?.data.errorMessage || 'There was an error.',
              'error'
            );
      } else {
        if ((error as any).error === Constants.LOGIN_REQUIRED) {
          Rollbar.warning(error);
          loginWithRedirect();
          return;
        }

        Rollbar.error(error);
        handleError
          ? handleError(error as Error)
          : showSnackbar(
              isDevelopment ? (error as Error).message : 'There was an error.',
              'error'
            );
      }
    },
    [navigate, showSnackbar, loginWithRedirect]
  );

  /*========== REQUEST FACTORIES ==========*/
  const deleteResource = useCallback(
    (
      resource: ResourceType,
      scope: string,
      responseTransformer?: (data: string) => any,
      version = API_VERSION.v1
    ) => {
      return async ({
        urlParams,
        handleSuccess,
        handleError,
        handleFinally,
      }: RequestOptions) => {
        try {
          const token = await getToken();

          const resp = await axios.delete(
            `/${version}/${resource}/${urlParams}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              transformResponse: responseTransformer,
            }
          );

          handleSuccess(resp.data);
        } catch (error) {
          handleCustomAndDefaultError(error, handleError);
        } finally {
          handleFinally && handleFinally();
        }
      };
    },
    [getToken, handleCustomAndDefaultError]
  );

  const searchResource = useCallback(
    (resource: ResourceType, scope: string, version = API_VERSION.v1) => {
      return async ({
        handleError,
        handleFinally,
        handleSuccess,
        query,
        urlParams,
      }: SearchRequestOptions) => {
        try {
          const token = await getToken();

          const resp = await axios.get(
            `/${version}/${resource}?query=${query}${urlParams}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          handleSuccess(resp.data);
        } catch (error) {
          handleCustomAndDefaultError(error, handleError);
        } finally {
          handleFinally && handleFinally();
        }
      };
    },
    [getToken, handleCustomAndDefaultError]
  );
  const getResource = useCallback(
    (
      resource: ResourceType,
      responseTransformer: (data: string) => any,
      version = API_VERSION.v1
    ) => {
      return async ({
        urlParams,
        handleSuccess,
        handleError,
        handleFinally,
      }: RequestOptions) => {
        try {
          const token = await getToken();

          const resp = await axios.get(`/${version}/${resource}/${urlParams}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            transformResponse: responseTransformer,
          });
          handleSuccess(resp.data, token);
        } catch (error) {
          handleCustomAndDefaultError(error, handleError);
        } finally {
          handleFinally && handleFinally();
        }
      };
    },
    [getToken, handleCustomAndDefaultError]
  );

  const patchResource = useCallback(
    (
      resource: ResourceType,
      scope: string,
      responseTransformer: (data: string) => any,
      version = API_VERSION.v1
    ) => {
      return async ({
        urlParams,
        data,
        handleSuccess,
        handleError,
        handleFinally,
      }: PostPatchRequestOptions) => {
        try {
          const token = await getToken();

          const resp = await axios.patch(
            `/${version}/${resource}/${urlParams || ''}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              transformResponse: responseTransformer,
            }
          );

          handleSuccess && handleSuccess(resp.data, token);
        } catch (error) {
          handleCustomAndDefaultError(error, handleError);
        } finally {
          handleFinally && handleFinally();
        }
      };
    },
    [getToken, handleCustomAndDefaultError]
  );

  const postResource = useCallback(
    (
      resource: ResourceType,
      scope: string,
      responseTransformer: (data: string) => any,
      version = API_VERSION.v1
    ) => {
      return async ({
        urlParams,
        data,
        headers,
        handleSuccess,
        handleError,
        handleFinally,
      }: PostPatchRequestOptions) => {
        try {
          const token = await getToken();

          const resp = await axios.post(
            `/${version}/${resource}/${urlParams || ''}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                // if headers are passed in, add them to the request
                // otherwise, spread an empty object
                ...(headers && {}),
              },
              transformResponse: responseTransformer,
            }
          );

          handleSuccess && handleSuccess(resp.data, token);
        } catch (error) {
          handleCustomAndDefaultError(error, handleError);
        } finally {
          handleFinally && handleFinally();
        }
      };
    },
    [getToken, handleCustomAndDefaultError]
  );

  return {
    //SEARCH REQUESTS
    searchCompanies: React.useMemo(
      () => searchResource(RESOURCE_TYPES.COMPANIES, 'read:company'),
      [searchResource]
    ),
    searchFormulas: React.useMemo(
      () => searchResource(RESOURCE_TYPES.FORMULAS, 'read:formula'),
      [searchResource]
    ),
    searchProjects: React.useMemo(
      () => searchResource(RESOURCE_TYPES.PROJECTS, 'read:project'),
      [searchResource]
    ),
    searchRawMaterials: React.useMemo(
      () =>
        searchResource(
          RESOURCE_TYPES.RAW_MATERIALS,
          'read:raw-materials',
          API_VERSION.v2
        ),
      [searchResource]
    ),
    // GET REQUESTS
    getCustomer: React.useMemo(
      () => getResource(RESOURCE_TYPES.CUSTOMERS, showTransformer),
      [getResource]
    ),
    getCompanies: React.useMemo(
      () => getResource(RESOURCE_TYPES.COMPANIES, indexTransformer),
      [getResource]
    ),
    getCompany: React.useMemo(
      () => getResource(RESOURCE_TYPES.COMPANIES, showTransformer),
      [getResource]
    ),
    getCompanyCustomers: React.useMemo(
      () => getResource(RESOURCE_TYPES.COMPANIES, indexTransformer),
      [getResource]
    ),
    getCompanyProjects: React.useMemo(
      () => getResource(RESOURCE_TYPES.COMPANIES, indexTransformer),
      [getResource]
    ),
    getCustomerNotifications: React.useMemo(
      () =>
        getResource(RESOURCE_TYPES.CUSTOMERS, indexTransformer, API_VERSION.v2),
      [getResource]
    ),
    patchCustomerNotificationsMarkAllAsViewed: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.CUSTOMERS,
          'update:notifications',
          indexTransformer,
          API_VERSION.v2
        ),
      [patchResource]
    ),
    patchEmployee: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.EMPLOYEES,
          'update:employee',
          showTransformer
        ),
      [patchResource]
    ),
    getFormula: React.useMemo(
      () => getResource(RESOURCE_TYPES.FORMULAS, showTransformer),
      [getResource]
    ),
    getFormulaBatches: React.useMemo(
      () => getResource(RESOURCE_TYPES.FORMULAS, indexTransformer),
      [getResource]
    ),
    getFormulaPriceQuote: React.useMemo(
      () => getResource(RESOURCE_TYPES.FORMULAS, showTransformer),
      [getResource]
    ),
    getFormulaWarnings: React.useMemo(
      () => getResource(RESOURCE_TYPES.FORMULAS, notificationsTransformer),
      [getResource]
    ),
    getInci: React.useMemo(
      () => getResource(RESOURCE_TYPES.INCIS, showTransformer),
      [getResource]
    ),
    getIncis: React.useMemo(
      () => getResource(RESOURCE_TYPES.INCIS, indexTransformer),
      [getResource]
    ),
    getProject: React.useMemo(
      () => getResource(RESOURCE_TYPES.PROJECTS, showTransformer),
      [getResource]
    ),
    getProjectFeedback: React.useMemo(
      () => getResource(RESOURCE_TYPES.PROJECTS, indexTransformer),
      [getResource]
    ),
    getProjectFormulas: React.useMemo(
      () => getResource(RESOURCE_TYPES.PROJECTS, indexTransformer),
      [getResource]
    ),
    getRawMaterial: React.useMemo(
      () =>
        getResource(
          RESOURCE_TYPES.RAW_MATERIALS,
          showTransformer,
          API_VERSION.v2
        ),
      [getResource]
    ),
    getRawMaterials: React.useMemo(
      () =>
        getResource(
          RESOURCE_TYPES.RAW_MATERIALS,
          indexTransformer,
          API_VERSION.v2
        ),
      [getResource]
    ),
    getManufacturers: React.useMemo(
      () => getResource(RESOURCE_TYPES.MANUFACTURERS, indexTransformer),
      [getResource]
    ),
    getSuppliers: React.useMemo(
      () => getResource(RESOURCE_TYPES.SUPPLIERS, indexTransformer),
      [getResource]
    ),
    getEmployee: React.useMemo(
      () => getResource(RESOURCE_TYPES.EMPLOYEES, showTransformer),
      [getResource]
    ),
    getProjectFile: React.useMemo(
      () => getResource(RESOURCE_TYPES.PROJECTS, showTransformer),
      [getResource]
    ),
    getProjectFiles: React.useMemo(
      () => getResource(RESOURCE_TYPES.PROJECTS, indexTransformer),
      [getResource]
    ),
    getProjectFileUrl: React.useMemo(
      () => getResource(RESOURCE_TYPES.PROJECTS, notificationsTransformer),
      [getResource]
    ),
    // POST REQUESTS
    postCustomer: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.CUSTOMERS,
          'create:customer',
          showTransformer
        ),
      [postResource]
    ),
    postFormulaBatch: React.useMemo(
      () =>
        postResource(RESOURCE_TYPES.FORMULAS, 'create:batch', showTransformer),
      [postResource]
    ),
    postEmail: React.useMemo(
      () =>
        postResource(RESOURCE_TYPES.EMAILS, 'create:email', showTransformer),
      [postResource]
    ),
    postEmployee: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.EMPLOYEES,
          'create:employee',
          showTransformer
        ),
      [postResource]
    ),
    postFinalBrief: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.FINAL_BRIEFS,
          'create:final_brief',
          showTransformer
        ),
      [postResource]
    ),
    postFormula: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.FORMULAS,
          'create:formula',
          showTransformer
        ),
      [postResource]
    ),
    postFormulaIngredients: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.FORMULAS,
          'create:ingredient',
          showTransformer
        ),
      [postResource]
    ),
    postFormulaPriceQuote: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.FORMULAS,
          'create:pricing_quote',
          showTransformer
        ),
      [postResource]
    ),
    postInci: React.useMemo(
      () => postResource(RESOURCE_TYPES.INCIS, 'create:incis', showTransformer),
      [postResource]
    ),
    postProjectOrder: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.PROJECTS,
          'create:order',
          noOpTransformer,
          API_VERSION.v2
        ),
      [postResource]
    ),
    postPhase: React.useMemo(
      () =>
        postResource(RESOURCE_TYPES.PHASES, 'create:phase', showTransformer),
      [postResource]
    ),
    postProject: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.PROJECTS,
          'create:project',
          showTransformer
        ),
      [postResource]
    ),
    postProjectFeedback: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.PROJECTS,
          'create:feedback',
          showTransformer
        ),
      [postResource]
    ),
    postProjectV2: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.PROJECTS,
          'create:project',
          showTransformer,
          API_VERSION.v2
        ),
      [postResource]
    ),
    postRawMaterial: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.RAW_MATERIALS,
          'create:raw-material',
          showTransformer,
          API_VERSION.v2
        ),
      [postResource]
    ),
    postManufacturers: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.MANUFACTURERS,
          'create:manufacturer',
          showTransformer
        ),
      [postResource]
    ),
    postSuppliers: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.SUPPLIERS,
          'create:supplier',
          showTransformer
        ),
      [postResource]
    ),
    postProjectFile: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.PROJECTS,
          'create:document',
          indexTransformer
        ),
      [postResource]
    ),
    postIngredientListFeedback: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.DOCUMENTS,
          'create:feedback',
          showTransformer
        ),
      [postResource]
    ),
    postProjectThirdPartyTest: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.PROJECTS,
          'create:third-party-test',
          showTransformer
        ),
      [postResource]
    ),
    postProjectWorksheet: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.PROJECTS,
          'create:worksheet',
          showTransformer
        ),
      [postResource]
    ),
    postProjectWorksheetPriceQuote: React.useMemo(
      () =>
        postResource(
          RESOURCE_TYPES.PROJECTS,
          'create:price-quote',
          showTransformer
        ),
      [postResource]
    ),
    // PUT/PATCH REQUESTS
    patchCompany: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.COMPANIES,
          'update:company',
          showTransformer
        ),
      [patchResource]
    ),
    patchCustomer: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.CUSTOMERS,
          'update:customer',
          showTransformer
        ),
      [patchResource]
    ),
    patchFinalBrief: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.FINAL_BRIEFS,
          'update:final_brief',
          showTransformer
        ),
      [patchResource]
    ),
    patchFormula: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.FORMULAS,
          'update:formula',
          showTransformer
        ),
      [patchResource]
    ),
    patchFormulaBatch: React.useMemo(
      () =>
        patchResource(RESOURCE_TYPES.FORMULAS, 'update:batch', showTransformer),
      [patchResource]
    ),
    patchFormulaPriceQuote: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.FORMULAS,
          'update:pricing_quote',
          showTransformer
        ),
      [patchResource]
    ),
    patchInci: React.useMemo(
      () =>
        patchResource(RESOURCE_TYPES.INCIS, 'update:incis', showTransformer),
      [patchResource]
    ),
    patchIngredient: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.INGREDIENTS,
          'update:ingredient',
          showTransformer
        ),
      [patchResource]
    ),
    patchProject: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.PROJECTS,
          'update:project',
          showTransformer
        ),
      [patchResource]
    ),
    patchProjectOrderSuccess: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.PROJECTS,
          'update:order_success',
          noOpTransformer,
          API_VERSION.v2
        ),
      [patchResource]
    ),
    patchProjectV2: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.PROJECTS,
          'update:project',
          showTransformer,
          API_VERSION.v2
        ),
      [patchResource]
    ),
    patchRawMaterial: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.RAW_MATERIALS,
          'update:raw-material',
          showTransformer,
          API_VERSION.v2
        ),
      [patchResource]
    ),
    patchProjectThirdPartyTest: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.PROJECTS,
          'update:third-party-test',
          showTransformer
        ),
      [patchResource]
    ),
    patchProjectWorksheetPriceQuote: React.useMemo(
      () =>
        patchResource(
          RESOURCE_TYPES.PROJECTS,
          'update:price-quote',
          showTransformer
        ),
      [patchResource]
    ),
    // DELETE REQUESTS
    deleteCustomer: React.useMemo(
      () => deleteResource(RESOURCE_TYPES.CUSTOMERS, 'delete:customer'),
      [deleteResource]
    ),
    deleteFormula: React.useMemo(
      () => deleteResource(RESOURCE_TYPES.FORMULAS, 'delete:formula'),
      [deleteResource]
    ),
    deletePhase: React.useMemo(
      () =>
        deleteResource(RESOURCE_TYPES.PHASES, 'delete:phase', indexTransformer),
      [deleteResource]
    ),
    deleteProjectOrder: React.useMemo(
      () =>
        deleteResource(
          RESOURCE_TYPES.PROJECTS,
          'delete:order',
          noOpTransformer,
          API_VERSION.v2
        ),
      [deleteResource]
    ),
    deleteInci: React.useMemo(
      () => deleteResource(RESOURCE_TYPES.INCIS, 'delete:inci'),
      [deleteResource]
    ),
    deleteIngredient: React.useMemo(
      () => deleteResource(RESOURCE_TYPES.INGREDIENTS, 'delete:ingredient'),
      [deleteResource]
    ),
    deleteProjectFile: React.useMemo(
      () =>
        deleteResource(
          RESOURCE_TYPES.PROJECTS,
          'delete:project',
          indexTransformer
        ),
      [deleteResource]
    ),
  };
};
