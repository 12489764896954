// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { Button, Select, TextArea, TextField, Typography } from 'design-system';
import {
  PRICE_QUOTE_FORM_KEYS,
  PRICE_SIZE_UNITS,
} from 'features/price-quote/price-quote.constants';
// Assets
import { PlusIcon } from '@heroicons/react/16/solid';
import { PriceQuoteFormValues } from '../form.config';
// Constants

const SIZE_LIMIT = 3;
const SIZE_KEYS = [
  {
    sizeQuantityKey: PRICE_QUOTE_FORM_KEYS.SIZE_ONE_QUANTITY,
    sizeUnitKey: PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT,
    sizeDescriptionKey: PRICE_QUOTE_FORM_KEYS.SIZE_ONE_DESCRIPTION,
    sizeMoqOneTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_ONE_TOTAL_COST_PER_UNIT,
    sizeMoqTwoTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_TWO_TOTAL_COST_PER_UNIT,
    sizeMoqThreeTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_THREE_TOTAL_COST_PER_UNIT,
  },
  {
    sizeQuantityKey: PRICE_QUOTE_FORM_KEYS.SIZE_TWO_QUANTITY,
    sizeUnitKey: PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT,
    sizeDescriptionKey: PRICE_QUOTE_FORM_KEYS.SIZE_TWO_DESCRIPTION,
    sizeMoqOneTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_TWO_MOQ_ONE_TOTAL_COST_PER_UNIT,
    sizeMoqTwoTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_TWO_MOQ_TWO_TOTAL_COST_PER_UNIT,
    sizeMoqThreeTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_TWO_MOQ_THREE_TOTAL_COST_PER_UNIT,
  },
  {
    sizeQuantityKey: PRICE_QUOTE_FORM_KEYS.SIZE_THREE_QUANTITY,
    sizeUnitKey: PRICE_QUOTE_FORM_KEYS.SIZE_THREE_UNIT,
    sizeDescriptionKey: PRICE_QUOTE_FORM_KEYS.SIZE_THREE_DESCRIPTION,
    sizeMoqOneTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_THREE_MOQ_ONE_TOTAL_COST_PER_UNIT,
    sizeMoqTwoTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_THREE_MOQ_TWO_TOTAL_COST_PER_UNIT,
    sizeMoqThreeTotalCostPerUnitKey:
      PRICE_QUOTE_FORM_KEYS.SIZE_THREE_MOQ_THREE_TOTAL_COST_PER_UNIT,
  },
];

export const SizeTable: React.FC = () => {
  const [sizeCount, setSizeCount] = React.useState(1);

  const handleAddSize = () => {
    if (sizeCount === SIZE_LIMIT) return;
    setSizeCount(sizeCount + 1);
  };

  const { getFieldProps, setFieldValue, values } = useFormikContext<
    PriceQuoteFormValues
  >();

  return (
    <div className="border rounded border-grey-90">
      <div className="grid grid-cols-4 rounded">
        <div className="col-span-1 bg-grey-20 flex items-center pl-6 border border-t-0 border-b-0 border-l-0 border-r-grey-90">
          <Typography variant="p" font="inter" weight="semibold" color="onDark">
            Size
          </Typography>
        </div>
        <div className="col-span-1 bg-grey-20 px-16 py-3 border border-t-0 border-b-0 border-l-0 border-r-grey-90 flex flex-col items-center">
          <Typography
            variant="h6"
            font="inter"
            weight="semibold"
            color="onDark"
            additionalStyles="whitespace-nowrap mb-2"
          >
            Order Quantity 1 (MOQ)
          </Typography>
          <TextField
            placeholder="0000"
            height="auto"
            type="number"
            {...getFieldProps(PRICE_QUOTE_FORM_KEYS.MOQ_ONE)}
          />
        </div>
        <div className="col-span-1 bg-grey-20 px-16 py-3 border border-t-0 border-b-0 border-l-0 border-r-grey-90 flex flex-col items-center">
          <Typography
            variant="h6"
            font="inter"
            weight="semibold"
            color="onDark"
            additionalStyles="whitespace-nowrap mb-2"
          >
            Order Quantity 2
          </Typography>
          <TextField
            placeholder="0000"
            height="auto"
            type="number"
            {...getFieldProps(PRICE_QUOTE_FORM_KEYS.MOQ_TWO)}
          />
        </div>
        <div className="col-span-1 bg-grey-20 px-16 py-3 flex flex-col items-center">
          <Typography
            variant="h6"
            font="inter"
            weight="semibold"
            color="onDark"
            additionalStyles="whitespace-nowrap mb-2"
          >
            Order Quantity 3
          </Typography>
          <TextField
            placeholder="0000"
            height="auto"
            type="number"
            {...getFieldProps(PRICE_QUOTE_FORM_KEYS.MOQ_THREE)}
          />
        </div>
      </div>

      {Array.from({ length: sizeCount }).map((_, index) => {
        const {
          sizeQuantityKey,
          sizeUnitKey,
          sizeDescriptionKey,
          sizeMoqOneTotalCostPerUnitKey,
          sizeMoqTwoTotalCostPerUnitKey,
          sizeMoqThreeTotalCostPerUnitKey,
        } = SIZE_KEYS[index];

        return (
          <div className="grid grid-cols-4" key={`size-row-${index + 1}`}>
            <div className="col-span-1 flex flex-col p-6 border border-t-0 border-l-0 border-r-grey-90 border-b-grey-90">
              <div className="flex flex-row">
                <TextField
                  placeholder="00"
                  height="auto"
                  label={`Size ${index + 1}`}
                  additionalClasses="mr-3"
                  type="number"
                  {...getFieldProps(sizeQuantityKey)}
                />
                <Select
                  label="Unit"
                  options={PRICE_SIZE_UNITS}
                  onChange={(option: Option) => {
                    setFieldValue(sizeUnitKey, option);
                  }}
                  value={values[sizeUnitKey]}
                />
              </div>
              <div>
                <TextArea
                  placeholder="Description"
                  height="auto"
                  label="Description (Optional)"
                  rows={2}
                  {...getFieldProps(sizeDescriptionKey)}
                />
              </div>
            </div>
            <div className="col-span-1 p-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
              <TextField
                label="Total"
                leadingText="$"
                trailingText="per unit"
                placeholder="00.00"
                height="auto"
                additionalClasses="white-space-nowrap"
                type="number"
                {...getFieldProps(sizeMoqOneTotalCostPerUnitKey)}
              />
            </div>
            <div className="col-span-1 p-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
              <TextField
                label="Total"
                leadingText="$"
                trailingText="per unit"
                placeholder="00.00"
                height="auto"
                additionalClasses="white-space-nowrap"
                type="number"
                {...getFieldProps(sizeMoqTwoTotalCostPerUnitKey)}
              />
            </div>
            <div className="col-span-1 p-6 border border-t-0 border-b-grey-90 border-l-0 border-r-grey-90">
              <TextField
                label="Total"
                leadingText="$"
                trailingText="per unit"
                placeholder="00.00"
                height="auto"
                additionalClasses="white-space-nowrap"
                type="number"
                {...getFieldProps(sizeMoqThreeTotalCostPerUnitKey)}
              />
            </div>
          </div>
        );
      })}

      <div className="grid grid-cols-4">
        <div className="col-span-1 p-3 border border-t-0 border-b-0 border-l-0 border-r-grey-90">
          <Button
            text="Add Size"
            leadingIcon={<PlusIcon />}
            size="large"
            type="soft"
            width="w-[225px]"
            action={handleAddSize}
          />
        </div>
        <div className="col-span-3"></div>
      </div>
    </div>
  );
};
