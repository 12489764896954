// Libraries
import React from 'react';
import { Formik, FormikHelpers } from 'formik';
// Components
import { BottomBar, Button, Divider, Typography } from 'design-system';
import {
  BasicInfo,
  MaterialResponsibility,
  Overview,
  Price,
  Terms,
} from './form-modules';
// Utils
import { PriceQuoteFormValues } from './form.config';
import { useSnackbar } from 'hooks';
import { priceQuoteValidationSchema } from '../validations';
// Assets
import { ReactComponent as PmLogo } from '../../../img/pm-logo-black.svg';
import {
  CompanyAttributes,
  IApiData,
  PriceQuoteAttributes,
  PriceQuoteType,
  useApi,
} from 'api';
import { DocumentIcon } from '@heroicons/react/24/outline';
// Constants
import {
  INITIAL_PRICE_QUOTE_FORM_VALUES,
  PRICE_QUOTE_FORM_KEYS,
  PRICE_QUOTE_TYPE,
  PRICE_SIZE_UNITS,
} from '../price-quote.constants';

interface PriceQuoteModalProps {
  quoteType: PriceQuoteType;
  company?: Maybe<IApiData<CompanyAttributes>>;
  projectId?: string;
  handleClose: () => void;
  priceQuote?: Maybe<IApiData<PriceQuoteAttributes>>; // TODO - Add API type
  setProject: (project: any) => void;
}

const generateQuoteVersionNumber = (
  company: Maybe<IApiData<CompanyAttributes>>,
  quoteType: PriceQuoteType
) => {
  if (!company) {
    throw new Error('Company is required to generate quote version number');
  }

  const typeIdentifier = quoteType === PriceQuoteType.preliminary ? 'P' : 'F';
  const companyInitials = company.attributes.name
    .split(' ')
    .map((word) => word.charAt(0))
    .join('');
  const date = new Date().toLocaleDateString().replace(/\//g, '');
  const uuidFragment = crypto.randomUUID().split('-')[0];
  return `${typeIdentifier}PQ-${companyInitials}-${date}-${uuidFragment}`;
};

export const PriceQuoteModal: React.FC<PriceQuoteModalProps> = ({
  quoteType,
  company,
  projectId,
  handleClose,
  priceQuote,
  setProject,
}) => {
  const {
    postProjectWorksheet,
    postProjectWorksheetPriceQuote,
    patchProjectWorksheetPriceQuote,
  } = useApi();
  const { showSnackbar } = useSnackbar();

  const parsedNum = (num: Maybe<number>) => {
    if (num === undefined) return;
    // @ts-ignore
    return typeof num === 'number' ? num : parseInt(num.replace(/,/g, ''));
  };

  const handleSubmit = (
    values: PriceQuoteFormValues,
    { setSubmitting }: FormikHelpers<PriceQuoteFormValues>
  ) => {
    const {
      personalNote,
      quoteVersionNumber,
      quoteDate,
      validFor,
      paymentTerms,
      incoterms,
      deliveryTerms,
      formulaDescription,
      moqOne,
      moqTwo,
      moqThree,
      sizeOneQuantity,
      sizeOneUnit,
      sizeOneDescription,
      sizeOneMoqOneTotalCostPerUnit,
      sizeOneMoqTwoTotalCostPerUnit,
      sizeOneMoqThreeTotalCostPerUnit,
      sizeTwoQuantity,
      sizeTwoUnit,
      sizeTwoDescription,
      sizeTwoMoqOneTotalCostPerUnit,
      sizeTwoMoqTwoTotalCostPerUnit,
      sizeTwoMoqThreeTotalCostPerUnit,
      sizeThreeQuantity,
      sizeThreeUnit,
      sizeThreeDescription,
      sizeThreeMoqOneTotalCostPerUnit,
      sizeThreeMoqTwoTotalCostPerUnit,
      sizeThreeMoqThreeTotalCostPerUnit,
      pricingNotes,
      materialsNote,
      rawMaterialResponsibility,
      containersResponsibility,
      decorationResponsibility,
      closureResponsibility,
      masterResponsibility,
      customerUuid,
      formulaUuid,
    } = values;
    setSubmitting(true);

    if (priceQuote) {
      // Update price quote
      patchProjectWorksheetPriceQuote({
        urlParams: `${projectId}/worksheets/${priceQuote.relationships.worksheet.data.id}/price_quotes/${priceQuote.id}`,
        data: {
          priceQuote: {
            quoteType: quoteType,
            personalNote,
            quoteVersionNumber,
            quoteDate,
            validFor: parsedNum(validFor),
            paymentTerms,
            incoterms,
            deliveryTerms,
            formulaDescription,
            moqOne: parsedNum(moqOne),
            moqTwo: parsedNum(moqTwo),
            moqThree: parsedNum(moqThree),
            sizeOneQuantity: parsedNum(sizeOneQuantity),
            sizeOneUnit: sizeOneUnit.value,
            sizeOneDescription,
            sizeOneMoqOneTotalCostPerUnit: parsedNum(
              sizeOneMoqOneTotalCostPerUnit
            ),
            sizeOneMoqTwoTotalCostPerUnit: parsedNum(
              sizeOneMoqTwoTotalCostPerUnit
            ),
            sizeOneMoqThreeTotalCostPerUnit: parsedNum(
              sizeOneMoqThreeTotalCostPerUnit
            ),
            sizeTwoQuantity: parsedNum(sizeTwoQuantity),
            sizeTwoUnit: sizeTwoUnit.value,
            sizeTwoDescription,
            sizeTwoMoqOneTotalCostPerUnit: parsedNum(
              sizeTwoMoqOneTotalCostPerUnit
            ),
            sizeTwoMoqTwoTotalCostPerUnit: parsedNum(
              sizeTwoMoqTwoTotalCostPerUnit
            ),
            sizeTwoMoqThreeTotalCostPerUnit: parsedNum(
              sizeTwoMoqThreeTotalCostPerUnit
            ),
            sizeThreeQuantity: parsedNum(sizeThreeQuantity),
            sizeThreeUnit: sizeThreeUnit.value,
            sizeThreeDescription,
            sizeThreeMoqOneTotalCostPerUnit: parsedNum(
              sizeThreeMoqOneTotalCostPerUnit
            ),
            sizeThreeMoqTwoTotalCostPerUnit: parsedNum(
              sizeThreeMoqTwoTotalCostPerUnit
            ),
            sizeThreeMoqThreeTotalCostPerUnit: parsedNum(
              sizeThreeMoqThreeTotalCostPerUnit
            ),
            pricingNotes,
            materialsNote,
            rawMaterialResponsibility,
            containersResponsibility,
            decorationResponsibility,
            closureResponsibility,
            masterResponsibility,
            customerUuid,
            formulaUuid,
          },
        },
        handleSuccess: () => {
          handleClose();
          showSnackbar('Price quote updated successfully', 'success');
          setProject((prevProject: any) => ({
            ...prevProject,
            priceQuotes: prevProject.priceQuotes.map(
              (pq: PriceQuoteAttributes) =>
                pq.id === priceQuote.id
                  ? { ...priceQuote, attributes: values }
                  : pq
            ),
          }));
        },
        handleFinally: () => {
          setSubmitting(false);
        },
        handleError: () => {
          setSubmitting(false);
          showSnackbar('Error updating price quote', 'error');
        },
      });
    } else {
      // Create new price quote
      postProjectWorksheet({
        urlParams: `${projectId}/worksheets`,
        data: {
          worksheet: {},
        },
        handleSuccess: (data: any) => {
          const worksheetId = data.id;

          postProjectWorksheetPriceQuote({
            urlParams: `${projectId}/worksheets/${worksheetId}/price_quotes`,
            data: {
              priceQuote: {
                quoteType: quoteType,
                personalNote,
                quoteVersionNumber,
                quoteDate,
                validFor: parsedNum(validFor),
                paymentTerms,
                incoterms,
                deliveryTerms,
                formulaDescription,
                moqOne: parsedNum(moqOne),
                moqTwo: parsedNum(moqTwo),
                moqThree: parsedNum(moqThree),
                sizeOneQuantity: parsedNum(sizeOneQuantity),
                sizeOneUnit: sizeOneUnit.value,
                sizeOneDescription,
                sizeOneMoqOneTotalCostPerUnit: parsedNum(
                  sizeOneMoqOneTotalCostPerUnit
                ),
                sizeOneMoqTwoTotalCostPerUnit: parsedNum(
                  sizeOneMoqTwoTotalCostPerUnit
                ),
                sizeOneMoqThreeTotalCostPerUnit: parsedNum(
                  sizeOneMoqThreeTotalCostPerUnit
                ),
                sizeTwoQuantity: parsedNum(sizeTwoQuantity),
                sizeTwoUnit: sizeTwoUnit.value,
                sizeTwoDescription,
                sizeTwoMoqOneTotalCostPerUnit: parsedNum(
                  sizeTwoMoqOneTotalCostPerUnit
                ),
                sizeTwoMoqTwoTotalCostPerUnit: parsedNum(
                  sizeTwoMoqTwoTotalCostPerUnit
                ),
                sizeTwoMoqThreeTotalCostPerUnit: parsedNum(
                  sizeTwoMoqThreeTotalCostPerUnit
                ),
                sizeThreeQuantity: parsedNum(sizeThreeQuantity),
                sizeThreeUnit: sizeThreeUnit.value,
                sizeThreeDescription,
                sizeThreeMoqOneTotalCostPerUnit: parsedNum(
                  sizeThreeMoqOneTotalCostPerUnit
                ),
                sizeThreeMoqTwoTotalCostPerUnit: parsedNum(
                  sizeThreeMoqTwoTotalCostPerUnit
                ),
                sizeThreeMoqThreeTotalCostPerUnit: parsedNum(
                  sizeThreeMoqThreeTotalCostPerUnit
                ),
                pricingNotes,
                materialsNote,
                rawMaterialResponsibility,
                containersResponsibility,
                decorationResponsibility,
                closureResponsibility,
                masterResponsibility,
                customerUuid,
                formulaUuid,
              },
            },
            handleSuccess: () => {
              handleClose();
              showSnackbar('Price quote created successfully', 'success');
            },
            handleFinally: () => {
              setSubmitting(false);
            },
          });
        },
        handleError: () => {
          setSubmitting(false);
          showSnackbar('Error creating price quote', 'error');
        },
      });
    }
  };

  const initialValues: PriceQuoteFormValues = priceQuote
    ? {
        ...(priceQuote.attributes as any),
        [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT]:
          PRICE_SIZE_UNITS.find(
            (unit) => unit.value === priceQuote.attributes.sizeOneUnit
          ) ||
          INITIAL_PRICE_QUOTE_FORM_VALUES[PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT],
        [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT]:
          PRICE_SIZE_UNITS.find(
            (unit) => unit.value === priceQuote.attributes.sizeTwoUnit
          ) ||
          INITIAL_PRICE_QUOTE_FORM_VALUES[PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT],
        [PRICE_QUOTE_FORM_KEYS.SIZE_THREE_UNIT]:
          PRICE_SIZE_UNITS.find(
            (unit) => unit.value === priceQuote.attributes.sizeThreeUnit
          ) ||
          INITIAL_PRICE_QUOTE_FORM_VALUES[
            PRICE_QUOTE_FORM_KEYS.SIZE_THREE_UNIT
          ],
        [PRICE_QUOTE_FORM_KEYS.CUSTOMER_UUID]:
          priceQuote.relationships.customer.data.id,
        [PRICE_QUOTE_FORM_KEYS.FORMULA_UUID]:
          priceQuote.relationships.formula.data.id,
      }
    : {
        ...INITIAL_PRICE_QUOTE_FORM_VALUES,
        [PRICE_QUOTE_FORM_KEYS.QUOTE_VERSION_NUMBER]: generateQuoteVersionNumber(
          company,
          quoteType
        ),
      };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={priceQuoteValidationSchema}
      enableReinitialize
    >
      {({ submitForm, isSubmitting }) => {
        return (
          <>
            <div className="p-6 flex flex-col gap-6 overflow-visible overflow-y-scroll">
              <div className="flex flex-row">
                <div className="mr-6">
                  <PmLogo />
                </div>
                <div>
                  <Typography variant="p" font="agipo" size="xl">
                    {PRICE_QUOTE_TYPE[quoteType]?.title}
                  </Typography>
                </div>
              </div>

              <BasicInfo />
              <div className="flex flex-col border rounded border-grey-90 p-6">
                <div className="flex flex-row justify-between">
                  <Overview companyId={company?.id} />
                  <Terms />
                </div>

                <Divider className="my-12" />

                <Price projectId={projectId} />

                <Divider className="my-12" />

                <MaterialResponsibility />
              </div>
            </div>
            <BottomBar
              buttons={[
                <Button
                  size="large"
                  text="Cancel"
                  type="secondary"
                  disabled={isSubmitting}
                />,

                <Button
                  size="large"
                  text={priceQuote ? 'Update Draft' : 'Save Draft'}
                  type="secondary"
                  leadingIcon={<DocumentIcon />}
                  action={submitForm}
                  disabled={isSubmitting}
                />,
              ]}
              additionalStyles="left-0"
            />
          </>
        );
      }}
    </Formik>
  );
};
