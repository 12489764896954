// Libraries
import React from 'react';
// Components
import { RadioButton, TextArea, Typography } from 'design-system';
// Utils
import { useFormikContext } from 'formik';
import { PriceQuoteFormValues } from '../form.config';
// Constants
import {
  MATERIAL_RESPONSIBILITY_OPTIONS,
  PRICE_QUOTE_FORM_KEYS,
} from 'features/price-quote/price-quote.constants';

export const MaterialResponsibility: React.FC = () => {
  const { getFieldProps, setFieldValue, values } = useFormikContext<
    PriceQuoteFormValues
  >();

  return (
    <div className="flex flex-col gap-12">
      <Typography font="inter" variant="h3" color="primary" weight="semibold">
        Material Responsibilities
      </Typography>
      <div className="border rounded border-grey-90">
        <div className="grid grid-cols-3 rounded">
          <div className="col-span-1 bg-grey-20 p-6 border border-t-0 border-b-0 border-l-0 border-r-grey-90" />

          <div className="col-span-1 bg-grey-20 flex justify-center items-center border border-t-0 border-b-0 border-l-0 border-r-grey-90">
            <Typography variant="p" font="inter" weight="medium" color="onDark">
              Customer
            </Typography>
          </div>

          <div className="col-span-1 bg-grey-20 flex justify-center items-center border border-t-0 border-b-0 border-l-0 border-r-grey-90">
            <Typography
              variant="p"
              font="inter"
              weight="medium"
              color="onDark"
              additionalStyles="whitespace-nowrap"
            >
              Prime Matter Labs
            </Typography>
          </div>
        </div>

        <div className="grid grid-cols-3">
          <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <Typography font="inter" variant="p" color="primary">
              Raw Materials
            </Typography>
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(
                PRICE_QUOTE_FORM_KEYS.RAW_MATERIAL_RESPONSIBILITY
              )}
              primaryLabel=""
              checked={
                values.rawMaterialResponsibility ===
                MATERIAL_RESPONSIBILITY_OPTIONS.customer
              }
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.RAW_MATERIAL_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.customer
                )
              }
            />
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(
                PRICE_QUOTE_FORM_KEYS.RAW_MATERIAL_RESPONSIBILITY
              )}
              primaryLabel=""
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.RAW_MATERIAL_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs
                )
              }
            />
          </div>
        </div>

        <div className="grid grid-cols-3">
          <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <Typography font="inter" variant="p" color="primary">
              Containers
            </Typography>
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(
                PRICE_QUOTE_FORM_KEYS.CONTAINERS_RESPONSIBILITY
              )}
              primaryLabel=""
              checked={
                values.containersResponsibility ===
                MATERIAL_RESPONSIBILITY_OPTIONS.customer
              }
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.CONTAINERS_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.customer
                )
              }
            />
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(
                PRICE_QUOTE_FORM_KEYS.CONTAINERS_RESPONSIBILITY
              )}
              primaryLabel=""
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.CONTAINERS_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs
                )
              }
            />
          </div>
        </div>

        <div className="grid grid-cols-3">
          <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <Typography font="inter" variant="p" color="primary">
              Decoration
            </Typography>
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(
                PRICE_QUOTE_FORM_KEYS.DECORATION_RESPONSIBILITY
              )}
              primaryLabel=""
              checked={
                values.decorationResponsibility ===
                MATERIAL_RESPONSIBILITY_OPTIONS.customer
              }
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.DECORATION_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.customer
                )
              }
            />
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(
                PRICE_QUOTE_FORM_KEYS.DECORATION_RESPONSIBILITY
              )}
              primaryLabel=""
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.DECORATION_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs
                )
              }
            />
          </div>
        </div>

        <div className="grid grid-cols-3">
          <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <Typography font="inter" variant="p" color="primary">
              Closure
            </Typography>
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(PRICE_QUOTE_FORM_KEYS.CLOSURE_RESPONSIBILITY)}
              primaryLabel=""
              checked={
                values.closureResponsibility ===
                MATERIAL_RESPONSIBILITY_OPTIONS.customer
              }
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.CLOSURE_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.customer
                )
              }
            />
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(PRICE_QUOTE_FORM_KEYS.CLOSURE_RESPONSIBILITY)}
              primaryLabel=""
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.CLOSURE_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs
                )
              }
            />
          </div>
        </div>

        <div className="grid grid-cols-3">
          <div className="col-span-1 flex py-4 pl-8 border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <Typography font="inter" variant="p" color="primary">
              Master
            </Typography>
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(PRICE_QUOTE_FORM_KEYS.MASTER_RESPONSIBILITY)}
              primaryLabel=""
              checked={
                values.masterResponsibility ===
                MATERIAL_RESPONSIBILITY_OPTIONS.customer
              }
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.MASTER_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.customer
                )
              }
            />
          </div>
          <div className="col-span-1 flex justify-center items-center border border-t-grey-90 border-b-0 border-l-0 border-r-grey-90">
            <RadioButton
              {...getFieldProps(PRICE_QUOTE_FORM_KEYS.MASTER_RESPONSIBILITY)}
              primaryLabel=""
              onChange={() =>
                setFieldValue(
                  PRICE_QUOTE_FORM_KEYS.MASTER_RESPONSIBILITY,
                  MATERIAL_RESPONSIBILITY_OPTIONS.primeMatterLabs
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="w-1/2">
        <TextArea
          label="Material Notes (Optional)"
          rows={6}
          placeholder="Enter any notes on this pricing."
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.MATERIALS_NOTE)}
        />
      </div>
    </div>
  );
};
