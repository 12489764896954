//Libraries
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  createStyles,
  makeStyles,
  CircularProgress,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  Tooltip,
  MenuItem,
} from '@material-ui/core';
import {
  Create as CreateIcon,
  PersonAdd as PersonAddIcon,
  InsertLink as InsertLinkIcon,
} from '@material-ui/icons';
import { useParams, useSearchParams } from 'react-router-dom';
//Components
import { TabPanel, TabbedMenu, EditableField } from 'features/ui';
import { InternalBrief } from 'features/brief';
import { AssignmentDialog } from './assignment-dialog.component';
import { PERMISSIONS, Can, usePermissionCheck } from 'features/rbac';
import { PricingQuote } from 'features/pricing-quote';
import ProjectActionsMenu from './project-actions-menu.component';
import ProjectFormulasTable from './project-formulas-table.component';
import { TeamManagementDialog } from 'features/team-management';
import { ThirdPartyTestSelectionForm } from 'features/third-party-test';
import { UserHistory } from 'features/ui';
//Utils
import {
  useApi,
  BriefAttributes,
  EmployeeAttributes,
  IApiData,
  ProjectAttributes,
  CustomerAttributes,
  ThirdPartyTestAttributes,
  TaskAttributes,
  CompanyAttributes,
  PriceQuoteType,
  PriceQuoteAttributes,
} from 'api';
import { useSnackbar, useTitle } from 'hooks';
import { ITheme, interRegular } from 'styles/mui/themeV2';
import { TaskStatus } from 'features/home/customer-portal/types';
//Constants
import { ROUTES, UUID_SHOW_ROUTE_STRING } from 'features/navigation';
import { PRODUCT_CATEGORIES_AND_TYPES } from 'features/brief';
import { GeneratePriceQuoteModal } from './generate-price-quote-modal.component';

interface ProjectWithRelations extends IApiData<ProjectAttributes> {
  createdBy: Maybe<IApiData<EmployeeAttributes>>;
  updatedBy: Maybe<IApiData<EmployeeAttributes>>;
  customers: Array<IApiData<CustomerAttributes>>;
  thirdPartyTest: Maybe<IApiData<ThirdPartyTestAttributes>>;
  tasks: Array<IApiData<TaskAttributes>>;
  company: IApiData<CompanyAttributes>;
  priceQuotes: Maybe<Array<IApiData<PriceQuoteAttributes>>>;
}

export const renderProjectCategoryOptions = (): Array<React.ReactNode> => {
  return Object.keys(PRODUCT_CATEGORIES_AND_TYPES).map((pcGrouping) => {
    return PRODUCT_CATEGORIES_AND_TYPES[
      pcGrouping as 'available' | 'unavailable'
    ].map(
      (category: any, index: number): React.ReactNode => {
        return (
          <MenuItem key={`${category.name}-${index}`} value={category.name}>
            {category.name}
          </MenuItem>
        );
      }
    );
  });
};

const PROJECT_HOME_TAB_VALUES = {
  brief: 0,
  formulas: 1,
  pricing: 2,
  thirdPartyTest: 3,
};

const URL_PARAMS = {
  tab: 'tab',
  [PROJECT_HOME_TAB_VALUES.pricing]: 'pricing',
};

const MONDAY_PROJECT_BASE_URL =
  'https://prime-matter-labs-team.monday.com/boards/5284439546/views/116645555/pulses/';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(8),
      fontFamily: interRegular.fontFamily,
      color: theme.palette.gray.dark,
      textAlign: 'left',
    },
    verticalMenu: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },

    mondayLink: {
      color: theme.palette.gray.dark,
      textDecoration: 'underline',
      marginLeft: theme.spacing(3),
    },
    link: {
      paddingTop: '3px',
      cursor: 'pointer',
    },
    copyProjectLink: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

enum DialogTypes {
  assignment = 'assignment',
  invite = 'invite',
  priceQuote = 'priceQuote',
}

interface IRequestBody {
  employees?: Array<string>;
  category?: string;
}

export const ProjectPage = () => {
  const classes = useStyles();
  const { getProject, patchProject } = useApi();
  const { uuid }: { uuid?: string } = useParams();
  const { showSnackbar } = useSnackbar();
  const [project, setProject] = useState<Maybe<ProjectWithRelations>>(
    undefined
  );
  const [brief, setBrief] = useState<Maybe<IApiData<BriefAttributes>>>(
    undefined
  );
  const [customers, setCustomers] = useState<
    Array<IApiData<CustomerAttributes>>
  >([]);

  const [tabValue, setTabValue] = useState(PROJECT_HOME_TAB_VALUES.brief);
  const [isEditingCategory, setIsEditingCategory] = useState<boolean>(false);

  const [dialogType, setDialogType] = useState<Maybe<DialogTypes>>(undefined);

  const [quoteType, setQuoteType] = useState<Maybe<PriceQuoteType>>(undefined);

  const openPreliminaryQuoteModal = () => {
    setQuoteType(PriceQuoteType.preliminary);
    setDialogType(DialogTypes.priceQuote);
  };

  const openFinalQuoteModal = () => {
    setQuoteType(PriceQuoteType.final);
    setDialogType(DialogTypes.priceQuote);
  };

  const [companyName, setCompanyName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useTitle(project?.attributes.name);

  const isAbleToViewPricingQuote = usePermissionCheck({
    actions: [PERMISSIONS.VISIT_PRICING_QUOTE],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    project && setCompanyName(project.attributes.companyName);
  }, [project]);

  useEffect(() => {
    if (uuid) {
      getProject({
        urlParams: uuid,
        handleSuccess: (data: ProjectWithRelations) => {
          setProject(data);
          setBrief(data.attributes.brief.data);
          setCustomers(data.customers || []);
        },
        handleFinally: () => {
          if (
            searchParams.get(URL_PARAMS.tab) ===
            URL_PARAMS[PROJECT_HOME_TAB_VALUES.pricing]
          ) {
            setTabValue(PROJECT_HOME_TAB_VALUES.pricing);
          } else {
            setTabValue(PROJECT_HOME_TAB_VALUES.brief);
          }
        },
      });
    }
  }, [uuid, getProject, searchParams]);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    const urlParam = URL_PARAMS[newValue];
    urlParam && setSearchParams(`?${URL_PARAMS.tab}=${urlParam}`);
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditingCategory(false);
    project &&
      setProject({
        ...project,
        attributes: {
          ...project.attributes,
          category: event.target.value,
        },
      });
    project &&
      handleProjectUpdate(
        { category: event.target.value },
        project?.id,
        'Category'
      );
  };

  const handleProjectUpdate = (
    projectData: IRequestBody,
    projectId: string,
    projectItem: string
  ) => {
    setIsLoading(true);
    patchProject({
      urlParams: projectId,
      data: {
        project: projectData,
      },
      handleSuccess: () =>
        showSnackbar(`${projectItem} updated successfully!`, 'success'),
      handleFinally: () => setIsLoading(false),
    });
  };

  const AssignEmployeesToProject = async (
    projectId: string,
    employeeIds: Array<string>
  ) => {
    handleProjectUpdate(
      { employees: employeeIds },
      projectId,
      'Project assignment'
    );
  };

  const thirdPartyTestTask = project?.tasks?.find(
    (task) => task.attributes.mondayColumnId === 'status__1'
  );

  const isThirdPartyTestTaskPending =
    thirdPartyTestTask?.attributes.status === TaskStatus.pending;

  const handleCopyProjectLinkClick = () => {
    navigator.clipboard.writeText(`${origin}/your-projects/${project?.id}`);
    showSnackbar('Project URL copied to clipboard', 'success');
  };

  return (
    <>
      <Container className={classes.root}>
        <Grid container alignItems="center">
          <Grid container item xs={12}>
            <Grid alignItems="flex-end" container xs={11}>
              <Grid item>
                <Typography variant="h2">
                  {project?.attributes?.name}
                </Typography>
              </Grid>
              {project?.attributes?.mondayPulseId && (
                <>
                  <Grid item>
                    <Typography variant="caption">
                      <Can
                        perform={PERMISSIONS.VISIT_EMPLOYEE_PAGE}
                        yes={() => (
                          <Link
                            target="_blank"
                            rel="noreferrer"
                            href={`${MONDAY_PROJECT_BASE_URL}${project.attributes.mondayPulseId}`}
                            className={classes.mondayLink}
                          >
                            View on Monday.com
                          </Link>
                        )}
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      <Can
                        perform={PERMISSIONS.VISIT_EMPLOYEE_PAGE}
                        yes={() => (
                          <RouterLink
                            to={ROUTES.EMPLOYEE_VIEW_CUSTOMER_PROJECT.route.replace(
                              UUID_SHOW_ROUTE_STRING,
                              project.id
                            )}
                            className={classes.mondayLink}
                          >
                            Launch Customer View
                          </RouterLink>
                        )}
                      />
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={1} className={classes.verticalMenu}>
              <Can
                perform={PERMISSIONS.VISIT_EMPLOYEE_PAGE}
                yes={() => (
                  <>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <ProjectActionsMenu
                        projectId={project?.id}
                        openAssignmentDialog={() =>
                          setDialogType(DialogTypes.assignment)
                        }
                        openPreliminaryQuoteModal={openPreliminaryQuoteModal}
                        openFinalQuoteModal={openFinalQuoteModal}
                        company={project?.company}
                      />
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={5}
            alignItems="center"
            style={{ marginTop: '16px' }}
          >
            <Grid item>
              <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
                {project && (
                  <RouterLink
                    target="_blank"
                    className="underline"
                    to={ROUTES.EMPLOYEE_VIEW_CUSTOMER_DASHBOARD.route.replace(
                      UUID_SHOW_ROUTE_STRING,
                      project.attributes.company.data.id
                    )}
                  >
                    {companyName}
                  </RouterLink>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
                <EditableField
                  emptyFieldText={'No category'}
                  isEditing={isEditingCategory}
                  setIsEditing={setIsEditingCategory}
                >
                  <TextField
                    name="category"
                    id="category"
                    select
                    fullWidth
                    variant="outlined"
                    label="Project Category"
                    size="small"
                    value={project?.attributes.category || ''}
                    onChange={handleCategoryChange}
                  >
                    {renderProjectCategoryOptions()}
                  </TextField>
                </EditableField>
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                item
                alignItems="center"
                className={classes.copyProjectLink}
                onClick={handleCopyProjectLinkClick}
              >
                <Tooltip
                  title="Copy URL to share with Client"
                  placement="top-start"
                >
                  <Typography variant="body1" style={{ marginRight: 4 }}>
                    Copy Project URL
                  </Typography>
                </Tooltip>
                <InsertLinkIcon fontSize="small" />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={5}>
              <Grid
                container
                item
                sm={6}
                className={classes.link}
                onClick={() => setDialogType(DialogTypes.invite)}
              >
                <Typography variant="body1" style={{ marginRight: 4 }}>
                  Team Members ({customers.length})
                </Typography>
                <CreateIcon fontSize="small" />
              </Grid>
              <Grid
                container
                item
                sm={3}
                className={classes.link}
                onClick={() => setDialogType(DialogTypes.invite)}
              >
                <Typography variant="body1" style={{ marginRight: 4 }}>
                  Invite
                </Typography>
                <PersonAddIcon fontSize="small" />
              </Grid>
            </Grid>
            <Grid container item>
              <UserHistory
                createdBy={project?.createdBy?.attributes?.fullName}
                createdAt={project?.attributes?.createdAt}
                updatedBy={project?.updatedBy?.attributes?.fullName}
                updatedAt={project?.attributes?.updatedAt}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <TabbedMenu
          tabs={[
            {
              label: 'Brief',
            },
            {
              label: 'Formulas',
            },
            {
              label: 'Pricing Quote',
              disabled: !isAbleToViewPricingQuote,
            },
            {
              label: '3rd party tests',
            },
          ]}
          tabValue={tabValue}
          handleTabChange={handleChange}
        />
        <TabPanel
          padding={0}
          value={tabValue}
          index={PROJECT_HOME_TAB_VALUES.brief}
        >
          {brief && <InternalBrief />}
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={PROJECT_HOME_TAB_VALUES.formulas}
          padding={0}
        >
          <ProjectFormulasTable projectId={uuid as string} />
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={PROJECT_HOME_TAB_VALUES.pricing}
          padding={0}
        >
          <Can
            perform={PERMISSIONS.VISIT_PRICING_QUOTE}
            yes={() =>
              brief && (
                <PricingQuote
                  projectId={uuid as string}
                  brief={brief}
                  setTabValue={setTabValue}
                />
              )
            }
          />
        </TabPanel>
        <TabPanel
          value={tabValue}
          index={PROJECT_HOME_TAB_VALUES.thirdPartyTest}
          padding={0}
        >
          <ThirdPartyTestSelectionForm
            projectId={project?.id}
            thirdPartyTest={project?.thirdPartyTest}
            setProject={setProject}
            isLocked={isThirdPartyTestTaskPending}
          />
        </TabPanel>
      </Container>

      {quoteType && dialogType === DialogTypes.priceQuote && (
        <GeneratePriceQuoteModal
          quoteType={quoteType}
          company={project?.company}
          projectId={project?.id}
          toggleQuoteModal={() => {
            setDialogType(undefined);
            setQuoteType(undefined);
          }}
          priceQuote={
            project?.priceQuotes
              ? project.priceQuotes.find(
                  (priceQuote) => priceQuote.attributes.quoteType === quoteType
                )
              : undefined
          }
          setProject={setProject}
        />
      )}

      {project && (
        <AssignmentDialog
          open={dialogType === DialogTypes.assignment}
          handleClose={() => setDialogType(undefined)}
          item={project}
          assignableItemType="project"
          AssignEmployeesToItem={AssignEmployeesToProject}
        />
      )}
      <TeamManagementDialog
        companyUuid={(project as any)?.company?.id}
        customers={customers}
        setCustomers={setCustomers}
        isOpen={dialogType === DialogTypes.invite}
        handleClose={() => setDialogType(undefined)}
      />
    </>
  );
};
