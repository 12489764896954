// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { TextField } from 'design-system';
// Utils
import { PriceQuoteFormValues } from '../form.config';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from 'features/price-quote/price-quote.constants';
import { SearchFormula } from './helper-components';

interface FormulaProps {
  projectId?: string;
}

export const Formula: React.FC<FormulaProps> = ({ projectId }) => {
  const { getFieldProps } = useFormikContext<PriceQuoteFormValues>();

  return (
    <div className="flex flex-row justify-between">
      <div className="w-[300px]">
        <SearchFormula projectId={projectId} />
      </div>
      <div className="w-[688px]">
        <TextField
          label="Description"
          placeholder="Describe this formula"
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.FORMULA_DESCRIPTION)}
        />
      </div>
    </div>
  );
};
