// Libraries
import * as Yup from 'yup';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from './price-quote.constants';

export const priceQuoteValidationSchema = Yup.object().shape({
  [PRICE_QUOTE_FORM_KEYS.CUSTOMER_UUID]: Yup.string()
    .required()
    .label('Customer Name'),
  // [PRICE_QUOTE_FORM_KEYS.QUOTE_DATE]: Yup.string()
  //   .required()
  //   .label('Quote Date'),
  [PRICE_QUOTE_FORM_KEYS.VALID_FOR]: Yup.number().required().label('Valid for'),
  // [PRICE_QUOTE_FORM_KEYS.PAYMENT_TERMS]: Yup.string()
  //   .required()
  //   .label('Payment Terms'),
  // [PRICE_QUOTE_FORM_KEYS.INCOTERMS]: Yup.string().required().label('Incoterms'),
  // [PRICE_QUOTE_FORM_KEYS.DELIVERY_TERMS]: Yup.string()
  //   .required()
  //   .label('Delivery Terms'),
  [PRICE_QUOTE_FORM_KEYS.FORMULA_UUID]: Yup.string()
    .required()
    .label('Formula Number'),
  // [PRICE_QUOTE_FORM_KEYS.FORMULA_DESCRIPTION]: Yup.string().required().label('Formula Description'),
  // [PRICE_QUOTE_FORM_KEYS.MOQ_ONE]: Yup.number().required().label('MOQ One'),
  // [PRICE_QUOTE_FORM_KEYS.MOQ_TWO]: Yup.number().required().label('MOQ Two'),
  // [PRICE_QUOTE_FORM_KEYS.MOQ_THREE]: Yup.number().required().label('MOQ Three'),
  // [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_QUANTITY]: Yup.number().required().label('Size One Quantity'),
  // [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_UNIT]: Yup.object().shape({
  //   value: Yup.string().required().label('Size One Unit'),
  //   label: Yup.string().required().label('Size One Unit'),
  // }).nullable().required().label('Size One Unit'),
  // [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_DESCRIPTION]: Yup.string().required().label('Size One Description'),
  // [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_ONE_TOTAL_COST_PER_UNIT]: Yup.number().required().label('Size One MOQ One Total Cost Per Unit'),
  // [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_TWO_TOTAL_COST_PER_UNIT]: Yup.number().required().label('Size One MOQ Two Total Cost Per Unit'),
  // [PRICE_QUOTE_FORM_KEYS.SIZE_ONE_MOQ_THREE_TOTAL_COST_PER_UNIT]: Yup.number().required().label('Size One MOQ Three Total Cost Per Unit'),
  // [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_QUANTITY]: Yup.number().required().label('Size Two Quantity'),
  // [PRICE_QUOTE_FORM_KEYS.SIZE_TWO_UNIT]: Yup.object().shape({}).nullable().required().label('Size Two Unit'),
  // [PRICE_QUOTE_FORM_KEYS.RAW_MATERIAL_RESPONSIBILITY]: Yup.string()
  //   .required()
  //   .label('Raw Materials'),
  // [PRICE_QUOTE_FORM_KEYS.CONTAINERS_RESPONSIBILITY]: Yup.string()
  //   .required()
  //   .label('Containers'),
  // [PRICE_QUOTE_FORM_KEYS.DECORATION_RESPONSIBILITY]: Yup.string()
  //   .required()
  //   .label('Decoration'),
  // [PRICE_QUOTE_FORM_KEYS.CLOSURE_RESPONSIBILITY]: Yup.string()
  //   .required()
  //   .label('Closure'),
  // [PRICE_QUOTE_FORM_KEYS.MASTER_RESPONSIBILITY]: Yup.string()
  //   .required()
  //   .label('Master'),
});
