// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { TextField, Typography } from 'design-system';
// Utils
import { PriceQuoteFormValues } from '../form.config';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from 'features/price-quote/price-quote.constants';

export const Terms: React.FC = () => {
  const { getFieldProps } = useFormikContext<PriceQuoteFormValues>();

  return (
    <div className="flex flex-col gap-6 w-[412px]">
      <Typography font="inter" variant="h3" color="primary" weight="semibold">
        Terms
      </Typography>
      <div>
        <TextField
          label="Payment Terms"
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.PAYMENT_TERMS)}
          height={null}
        />
      </div>
      <div>
        <TextField
          label="Incoterms"
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.INCOTERMS)}
          height={null}
        />
      </div>
      <div>
        <TextField
          label="Delivery Terms"
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.DELIVERY_TERMS)}
          height={null}
        />
      </div>
    </div>
  );
};
