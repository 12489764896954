// Libraries
import React from 'react';
import {
  IApiData,
  CompanyAttributes,
  PriceQuoteType,
  PriceQuoteAttributes,
} from 'api';
import { FullScreenModal } from 'design-system';
import { PriceQuoteModal } from 'features/price-quote';

interface GeneratePriceQuoteModalProps {
  quoteType: PriceQuoteType;
  company: Maybe<IApiData<CompanyAttributes>>;
  projectId: Maybe<string>;
  toggleQuoteModal: () => void;
  priceQuote?: Maybe<IApiData<PriceQuoteAttributes>>;
  setProject: (project: any) => void;
}

export const GeneratePriceQuoteModal: React.FC<GeneratePriceQuoteModalProps> = ({
  quoteType,
  company,
  projectId,
  toggleQuoteModal,
  priceQuote,
  setProject,
}) => {
  const isPreliminaryQuote = quoteType === PriceQuoteType.preliminary;

  return (
    <FullScreenModal
      handleClose={toggleQuoteModal}
      header={`${isPreliminaryQuote ? 'Preliminary' : 'Final'} Quote Generator`}
      subHeader={`Use the below form to create a ${
        isPreliminaryQuote ? 'preliminary' : 'final'
      } pricing quote.`}
    >
      <PriceQuoteModal
        quoteType={quoteType}
        company={company}
        projectId={projectId}
        handleClose={toggleQuoteModal}
        priceQuote={priceQuote}
        setProject={setProject}
      />
    </FullScreenModal>
  );
};
