// Libraries
import React from 'react';
import { useFormikContext } from 'formik';
// Components
import { TextField, Typography } from 'design-system';
import { SearchCustomer } from './helper-components';
// Utils
import { PriceQuoteFormValues } from '../form.config';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from '../../price-quote.constants';

interface OverviewProps {
  companyId?: string;
}

export const Overview: React.FC<OverviewProps> = ({ companyId }) => {
  const { getFieldProps } = useFormikContext<PriceQuoteFormValues>();

  return (
    <div className="flex flex-col gap-6 w-[412px]">
      <Typography font="inter" variant="h3" color="primary" weight="semibold">
        Overview
      </Typography>
      <div>
        <SearchCustomer companyId={companyId} />
      </div>
      <div>
        <TextField
          label="Quote Date"
          placeholder="00/00/00"
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.QUOTE_DATE)}
          height={null}
        />
      </div>
      <div>
        <TextField
          label="Valid for"
          placeholder="00"
          trailingText="days"
          type="number"
          {...getFieldProps(PRICE_QUOTE_FORM_KEYS.VALID_FOR)}
          height={null}
        />
      </div>
    </div>
  );
};
