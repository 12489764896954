// Libraries
import React, { useEffect, useState } from 'react';
// Components
import { LocalSearch } from './local-search.component';
// Utils
import { useApi } from 'api';
import { useFormikContext } from 'formik';
import { PriceQuoteFormValues } from '../../form.config';
// Constants
import { PRICE_QUOTE_FORM_KEYS } from 'features/price-quote/price-quote.constants';

interface SearchCustomerProps {
  companyId?: string;
}

export const SearchCustomer: React.FC<SearchCustomerProps> = ({
  companyId,
}) => {
  const { getCompanyCustomers } = useApi();
  const { errors, setFieldValue, values } = useFormikContext<
    PriceQuoteFormValues
  >();
  // Local state
  const [customerOptions, setCustomerOptions] = useState([]);

  useEffect(() => {
    // if (!values.company.value) return;
    if (!companyId) return;

    getCompanyCustomers({
      urlParams: `${companyId}/customers`,
      handleSuccess: (response) => {
        setCustomerOptions(
          response.data.map((option: any) => {
            return {
              label: `${option.attributes.givenName} ${option.attributes.familyName}`,
              value: option.id,
            };
          })
        );
      },
    });
  }, [companyId, getCompanyCustomers]);

  return (
    <div>
      <LocalSearch
        clearField={() => {
          setFieldValue(PRICE_QUOTE_FORM_KEYS.CUSTOMER_UUID, '');
        }}
        label="Customer Name"
        options={customerOptions}
        onChange={(option: Option) => {
          setFieldValue(PRICE_QUOTE_FORM_KEYS.CUSTOMER_UUID, option.value);
        }}
        error={errors.customerUuid}
        helperText={errors.customerUuid}
        value={values.customerUuid}
      />
    </div>
  );
};
